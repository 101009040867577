import React from 'react';
import GlobalStyles from '@styles/GlobalStyles';
import { Helmet } from 'react-helmet';

import Navigation from '@core/Navigation';
import Footer from '@core/Footer';
import Seo from '@core/Seo';
import CookieBanner from '@core/CookieBanner';
import { isBrowser, scrollTo, isMobile } from '@utils';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import RegularFont from '@styles/fonts/diatype-regular.woff2';
import MediumFont from '@styles/fonts/diatype-medium.woff2';
// import Debug from '@core/Debug';

// should be kept in sync with the css variables used in the enter/exit classes
const transitions = {
  page: 500,
  navigation: 500,
  appear: 100,
};

const Layout = ({ children, location }) => (
  <>
    <GlobalStyles />
    <Seo />
    <Helmet>
      <link rel="preload" href={RegularFont} as="font" type="font/woff2" crossOrigin />
      <link rel="preload" href={MediumFont} as="font" type="font/woff2" crossOrigin />
    </Helmet>
    {/* <Debug /> */}
    <Navigation location={location} />
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        classNames="page-transition"
        appear
        timeout={{
          enter: transitions.page,
          exit: transitions.page,
          appear: transitions.appear,
        }}
        onEntered={() => {
          window.scrollTo(0, 0);
          if (location.hash) {
            if (isMobile) {
              scrollTo(location.hash, false);
            } else {
              setTimeout(() => {
                scrollTo(location.hash, true);
              }, 750);
            }
          } else {
            window.scrollTo(0, 0);
          }
        }}
      >
        <div className="page-transition-initial">
          {children}
          <Footer />
          {isBrowser && <CookieBanner />}
        </div>
      </CSSTransition>
    </TransitionGroup>
  </>
);
export default Layout;
