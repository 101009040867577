import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import withLink from '@common/withLink';
import { linkHover } from '@mixins';

import AppStoreSvg from '@icons/app-store.svg';
import GooglePlaySvg from '@icons/google-play.svg';

import mq from '@mq';
import { isBrowser } from '@utils';

const AppIcons = ({ className, context, stacked }) => {
  const [appendUrlParameters, setAppendUrlParameters] = useState(true);

  const onNavigate = useCallback(
    platform => {
      if (isBrowser) {
        window.fbq('trackCustom', `app-download-${platform}`, {
          event: 'click',
          context,
          location: window.location.href,
        });
      }

      if (!appendUrlParameters) {
        return;
      }

      removeLocalStorageUrlParameters();

      setAppendUrlParameters(false);
    },
    [appendUrlParameters, context]
  );

  if (!isBrowser) {
    return null;
  }

  return (
    <Container className={className} stacked={stacked}>
      <AppStoreLink
        {...(context && {
          className: `app-download-ios-${context}`,
        })}
        to={`https://api.denizenplatform.com/api/application/download?platform=ios${modifyUrl(
          appendUrlParameters
        )}`}
        onClick={() => {
          onNavigate('ios');
        }}
      >
        <AppStoreSvg />
      </AppStoreLink>
      <AppStoreLink
        {...(context && {
          className: `app-download-android-${context}`,
        })}
        to={`https://api.denizenplatform.com/api/application/download?platform=android${modifyUrl(
          appendUrlParameters
        )}`}
        onClick={() => {
          onNavigate('android');
        }}
      >
        <GooglePlaySvg />
      </AppStoreLink>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  row-gap: var(--space-xxs);
  column-gap: var(--space-s);

  ${props =>
    !props.stacked &&
    css`
      justify-items: center;
      align-items: center;
      justify-content: center;
      row-gap: var(--space-xs);

      ${mq.small} {
        grid-auto-flow: column;
      }
    `}
`;

const AppStoreLink = withLink(styled.div`
  ${linkHover};
`);

function modifyUrl(appendUrlParameters) {
  if (!isBrowser || !appendUrlParameters) {
    return '';
  }

  const urlParameters = localStorage.getItem('urlParameters');

  if (!urlParameters) {
    return '';
  }

  return `&${localStorage.getItem('urlParameters')}`;
}

function removeLocalStorageUrlParameters() {
  if (!isBrowser) {
    return null;
  }

  localStorage.removeItem('urlParameters');
  return null;
}

export default AppIcons;
