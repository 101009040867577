import { createGlobalStyle } from 'styled-components';
import mq, { mqdown } from '@mq';

const Vars = createGlobalStyle`
  :root {
    --space-xxxs: 0.375rem; // 6
    --space-xxs: 0.75rem; // 12
    --space-xs: 1.5rem; // 24
    --space-s: 3rem; // 48
    --space-m: 6rem; // 96
    --space-l: 7.75rem; // 124
    --space-xl: 12rem; // 192
    --space-xxl: 18rem; // 288
    
    /* --section-spacing: 1.875rem; // 30 */
    /* --spacing-small: 3.5rem; // 56px; */
    
    --gutter: 1.5rem; // 24
    --grid-gap: 1.5rem; // 24
    --grid-column-count: 4;
    --navigation-height: 6.125rem; //98

    ${mq.small} {
      --grid-column-count: 6;
    }

    ${mq.medium} {
      --grid-column-count: 12;
    }

    ${mq.huge} {
      --grid-column-count: 18;
    }

    --font-family: 'Diatype', sans-serif;
  
    --font-xxs: 0.875rem / 1.285 var(--font-family); //14
    --letter-spacing-xxs: 0.02em;
    --font-xs: 1rem / 1.29 var(--font-family); // 16
    --font-s: 1.5rem / 1.25 var(--font-family); // 24
    --font-m: 500 2rem / 1.085 var(--font-family); // 32
    --font-xm: 500 2.625rem/1.19 var(--font-family); //42
    --font-l: 500 4.0625rem/1.11 var(--font-family); // 65
    --letter-spacing-l: 0.005em; // 0.5%
    --font-xl: 500 5.625rem/1.07 var(--font-family); // 90
    --letter-spacing-xl: 0.015em; // 2%

    --font-body-mobile: 1.25rem / 1.25 var(--font-family); //20
    --font-subtitle: var(--font-xs);

    ${mq.small} {
      --font-subtitle: var(--font-s);
      --font-m: 2rem / 1.31 var(--font-family); // 32
    }
    
    --color-white: #fff;
    --color-black: #000;

    --color-text: #181818;
    --color-text-light: rgba(24, 24, 24, 0.4);
    
    --color-primary: #E8EE7B;
    --color-secondary: #EDEBE2;
    --color-grey: #BEBBAE;
    --color-background: #F8F7F3;
    --color-error: #D15755;

    --motion-half: 100ms;
    --motion: 200ms;
    --motion-plus-half: 300ms;
    --motion-double: 400ms;
    --motion-triple: 600ms;
    --motion-quadruple: 800ms;
    --motion-quintuple: 1000ms;

    --transition-page: 500ms;
    --transition-appear: 100ms;
    --transition-navigation: 500ms;
  }
`;

export default Vars;
