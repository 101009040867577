import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { linkHover, underline } from '@mixins';
import mq, { mqdown } from '@mq';
import withLink from '@common/withLink';

import Button from '@core/Button';

const CookieBanner = () => {
  const [showCookieBanner, setShowCookieBanner] = useState(true);

  const confirmConsent = useCallback(() => {
    window.localStorage.setItem('cookieSet', 'true');
    setShowCookieBanner(false);
  }, []);

  if (!showCookieBanner || window.localStorage.getItem('cookieSet') === 'true') {
    return null;
  }

  return (
    <Container>
      <div>
        By continuing to browse this site, you agree to the use of cookies to identify your session
        and to remember your login after you close the browser (authentication cookies).{` `}
        <LearnMoreLink to="/privacy-policy" title="Privacy Policy">
          Learn more
        </LearnMoreLink>
      </div>
      <Button onClick={confirmConsent}>Agree & Close</Button>
    </Container>
  );
};

const Container = styled.div`
  --background-color: var(--color-background);

  position: fixed;
  bottom: var(--space-xxs);
  left: var(--space-xxs);
  right: var(--space-xxs);
  border-radius: 1.5625rem;
  font: var(--font-xs);
  background-color: var(--color-secondary);
  padding: var(--space-xxs);
  display: grid;
  z-index: 3;
  row-gap: var(--space-xxs);

  ${mqdown.small} {
    text-align: center;
    justify-items: center;
  }

  ${mq.small} {
    bottom: var(--space-xs);
    left: var(--space-xs);
    right: var(--space-xs);
    padding: var(--space-xs);
    font: var(--font-s);
    grid-auto-flow: column;
    column-gap: var(--space-s);
    align-items: center;
  }
`;

const LearnMoreLink = withLink(styled.div`
  ${linkHover};
  ${underline};
`);

export default CookieBanner;
