const breakpoints = {
  small: 48, // 768px
  medium: 80, // 1280px
  large: 105, // 1680px
  huge: 160, // 2560px
};

const mq = {
  small: `@media (min-width: ${breakpoints.small}em)`,
  medium: `@media (min-width: ${breakpoints.medium}em)`,
  large: `@media (min-width: ${breakpoints.large}em)`,
  huge: `@media (min-width: ${breakpoints.huge}em)`,
};

const mqdown = {
  small: `@media (max-width: ${breakpoints.small - 0.01}em)`,
  medium: `@media (max-width: ${breakpoints.medium - 0.01}em)`,
  large: `@media (max-width: ${breakpoints.large - 0.01}em)`,
  huge: `@media (max-width: ${breakpoints.huge - 0.01}em)`,
};

const mqonly = {
  small: `@media (max-width: ${breakpoints.small - 0.01}em)`,
  medium: `@media (min-width: ${breakpoints.small}em) and (max-width: ${
    breakpoints.medium - 0.01
  }em)`,
  large: `@media (min-width: ${breakpoints.medium}em) and (max-width: ${
    breakpoints.large - 0.01
  }em)`,
  huge: `@media (min-width: ${breakpoints.huge}em)`,
};

export { mqdown, mqonly, breakpoints };
export default mq;
