/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { injectIntl, FormattedMessage, navigate } from 'gatsby-plugin-react-intl';

import withLink from '@common/withLink';
import Button from '@core/Button';
import Link from '@core/Link';

import LogoSvg from '@icons/logo.svg';
import CloseSvg from '@icons/close.svg';
import MenuSvg from '@icons/menu.svg';

import mq, { mqdown, mqonly } from '@mq';
import { cover, center, grid, linkHover } from '@mixins';

const Navigation = ({ homepage, location }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  const toggleExpand = useCallback(() => {
    setExpanded(previous => !previous);
  }, []);

  return (
    <Container>
      <HomeLink to="/">
        <LogoSvg />
      </HomeLink>

      <MainLinks>
        <MainLinksInner>
          <li>
            <Button to="/needspace">Need Space</Button>
          </li>
          <li>
            <Button to="/eiswerk">The House</Button>
          </li>
          <li>
            <Button to="/havespace">Have Space</Button>
          </li>
        </MainLinksInner>
      </MainLinks>
      <TopLinks>
        <SideLinks setExpanded={setExpanded} homepage={homepage} />
      </TopLinks>

      {!expanded && <MenuButton onClick={toggleExpand} />}
      {expanded && <CloseButton onClick={toggleExpand} />}

      <Menu expanded={expanded}>
        <Links>
          <li>
            <Button to="/needspace">Need Space</Button>
          </li>
          <li>
            <Button to="/eiswerk">The House</Button>
          </li>
          <li>
            <Button to="/havespace">Have Space</Button>
          </li>
        </Links>
        <Links>
          <SideLinks setExpanded={setExpanded} homepage={homepage} />
        </Links>
      </Menu>
    </Container>
  );
};

const SideLinks = ({ setExpanded }) => {
  const navigateToCoworking = useCallback(() => {
    if (setExpanded) {
      setExpanded(false);
    }

    navigate('/needspace/coworkingberlin/');
  }, [setExpanded]);

  const navigateToMeetingRooms = useCallback(() => {
    if (setExpanded) {
      setExpanded(false);
    }

    navigate('/needspace/meetingrooms/');
  }, [setExpanded]);

  return (
    <>
      <li>
        <SideLink onClick={navigateToCoworking}>
          <FormattedMessage id="coworking" />
        </SideLink>
      </li>
      <li>
        <SideLink onClick={navigateToMeetingRooms}>
          <FormattedMessage id="meetingrooms" />
        </SideLink>
      </li>
    </>
  );
};

const Container = styled.nav`
  display: flex;
  font: var(--font-s);
  padding: var(--space-xs) var(--gutter);
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  height: var(--navigation-height);
`;

const HomeLink = withLink(styled.a`
  ${linkHover};
  display: flex;
  z-index: 4;
  position: relative;
  transform: translate3d(0, 0, 0);
`);

const CloseButton = styled(CloseSvg)`
  z-index: 2;
  cursor: pointer;

  ${mq.medium} {
    display: none;
  }
`;

const MenuButton = styled(MenuSvg)`
  z-index: 1;
  cursor: pointer;

  ${mq.medium} {
    display: none;
  }
`;

const Menu = styled.div`
  ${cover};
  ${center};
  position: fixed;
  padding-top: var(--navigation-height);
  padding: var(--space-xs);
  background-color: var(--color-background);
  transition: transform var(--motion);

  ${props =>
    props.expanded
      ? css`
          transform: translate3d(0, 0, 0);
        `
      : css`
          transform: translate3d(-100%, 0, 0);
        `}
`;

const Links = styled.ul`
  display: grid;
  row-gap: var(--space-xxs);
  column-gap: var(--grid-gap);
  text-align: center;

  ${mq.medium} {
    grid-auto-flow: column;
  }

  ${mqdown.medium} {
    &:last-child {
      position: absolute;
      bottom: var(--space-xs);
    }
  }
`;

const SideLink = styled(Link)`
  ${mqonly.large} {
    font: var(--font-xs);
  }
`;

const TopLinks = styled(Links)`
  position: relative;
  z-index: 3;

  ${mqdown.medium} {
    display: none;
  }
`;

const MainLinks = styled.div`
  ${cover};
  padding: var(--space-xs) var(--gutter);
  z-index: 3;

  ${mqdown.medium} {
    display: none;
  }
`;

const MainLinksInner = styled.ul`
  ${grid};
  justify-items: center;

  li:first-child {
    grid-column: 2 / 7;

    ${mq.large} {
      grid-column: 3 / 7;
    }
  }

  li:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  li:last-child {
    grid-column: 7 / 12;

    ${mq.large} {
      grid-column: 7 / 11;
    }
  }
`;

export default injectIntl(Navigation);
