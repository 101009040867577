import { createGlobalStyle, css } from 'styled-components';
import mq, { mqdown } from '@mq';

import { truncate, layoutWrapper, grid, hover } from '@mixins';

const Base = createGlobalStyle`
  html,
  body {
    font-size: 16px;
    font-family: var(--font-family);
    line-height: 1.2;
    background: var(--color-white);
    color: var(--color-text);
    
    -webkit-font-smoothing: antialiased;
    background-color: var(--color-background);
  }

  ::selection {
    background-color: var(--color-secondary);
  }

  main {
    font: var(--font-body-mobile);

    ${mq.small} {
      font: var(--font-m);
    }
  }

  section {
    ${layoutWrapper};
    ${grid};

    padding-top: calc(var(--space-xl) / 2);
    padding-bottom: calc(var(--space-xl) / 2);

    ${mq.small} {
      padding-top: calc(var(--space-xxl) / 2);
      padding-bottom: calc(var(--space-xxl) / 2);
    }

    &:first-child {
      padding-top: var(--space-s);
    }

    ${mqdown.small} {
      &:last-child {
        padding-bottom: var(--space-xl);
      }
    }
  }

  h1 {
    font: var(--font-m);
    text-align: center;
    margin-bottom: var(--space-xxs);

    ${mq.small} {
      font: var(--font-l);
      letter-spacing: var(--letter-spacing-l);
      margin-bottom: var(--space-xs);
    }

    ${mq.medium} {
      font: var(--font-xl);
      letter-spacing: var(--letter-spacing-xl);
    }
  }
  
  h2 {
    font: var(--font-m);
    text-align: center;
    margin-bottom: var(--space-xxs);

    ${mq.small} {
      font: var(--font-l);
      letter-spacing: var(--letter-spacing-l);
      margin-bottom: var(--space-xs);
    }
  }

  h3 {
    font: var(--font-s);
    margin-bottom: var(--space-xxs);

    ${mq.small} {
      font: var(--font-m);
      margin-bottom: var(--space-xs);
    }
  }

  h4 {
  }

  h1 {
    grid-column: 1 / -1;

    ${mq.huge} {
      grid-column: 3 / -3;
    }
  }

  h2 {
    grid-column: 1 / -1;

    ${mq.medium} {
      grid-column: 2 / -2;
    }

    ${mq.large} {
      grid-column: 3 / -3;
    }

    ${mq.huge} {
      grid-column: 6 / -6;
    }
  }

  small,
  figcaption {
    font: var(--font-subtitle);
  }

  svg {
    display: block;
    fill: var(--color-text);

    .st0{
      fill: none;
    }
    
    .st1{
      fill: var(--color-background);
    }
  }

  figure {
    .gatsby-image-wrapper-constrained {
      display: block;
    }
  }

  .page-transition-initial {
    opacity: 0;
  }

  .page-transition-enter,
  .page-transition-appear {
    position: absolute;
    opacity: 0;
  }

  .page-transition-enter-done {
    transition: opacity var(--transition-page) ease-in-out;
    opacity: 1;
  }

  .page-transition-appear-done {
    transition: opacity var(--transition-appear) ease-in-out;
    opacity: 1;
  }

  .page-transition-exit {
    transition: opacity var(--transition-page) ease-in-out;
    opacity: 0;
  }
`;

export default Base;
