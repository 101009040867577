import * as React from 'react';
import styled, { css } from 'styled-components';

import withLink from '@common/withLink';
import mq, { mqdown } from '@mq';
import { hover } from '@mixins';

const Button = ({ disabled, title, children, large, ...rest }) => (
  <Container disabled={disabled} title={title} large={large} {...rest}>
    <Text>{children || title}</Text>
  </Container>
);

const Container = styled.button`
  --default-background-color: var(--color-secondary);
  --default-content-color: var(--color-text);
  --animation-duration: var(--motion-double);
  --animation-delay: var(--motion-half);

  padding: 0.75rem 3.875rem 0.5rem 3.875rem;
  font: var(--font-s);

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  background-color: var(--background-color, var(--default-background-color));
  color: var(--content-color, var(--default-content-color));

  border-radius: 6.25rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  transition: background-color var(--motion);

  ${mq.small} {
    &::before {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      content: '';
      background-color: var(--color-text);
      border-radius: 6.25rem;
      transform: translate3d(-100%, 0, 0);
      transition: transform var(--animation-duration) var(--animation-delay);
      z-index: 1;
    }
  }

  ${hover`
    --content-color: var(--color-secondary);

    &::before {
      transform: translate3d(0,0,0);
    }
  `}

  ${mq.medium} {
    width: 250px;
  }

  ${mqdown.small} {
    &:active {
      --background-color: var(--color-text);
      --content-color: var(--color-secondary);
    }
  }

  ${props =>
    props.large &&
    css`
      padding: 0.625rem 1.5rem 0.5rem 1.5rem;
      width: auto;

      ${mq.small} {
        padding: 0.9375rem 3.125rem 0.625rem 3.125rem;
        font: var(--font-xm);
      }

      ${mq.medium} {
        width: initial;
      }
    `}

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.link-active {
    --background-color: var(--color-text);
    --content-color: var(--color-secondary);
  }
`;

const Text = styled.span`
  position: relative;
  z-index: 1;
  color: var(--content-color, var(--default-content-color));
  transition: color var(--motion), background-color var(--motion);

  ${mq.small} {
    transition: color calc(var(--animation-duration) / 8)
      calc(var(--animation-duration) / 4 + var(--animation-delay));
  }
`;

export default withLink(Button);
