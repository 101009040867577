/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback } from 'react';
import styled from 'styled-components';
import { injectIntl, FormattedMessage, navigate } from 'gatsby-plugin-react-intl';

import AppIcons from '@components/AppIcons';

import mq, { mqdown } from '@mq';
import { grid, layoutWrapper, linkHover } from '@mixins';
import withLink from '@common/withLink';
import { scrollTo } from '@utils';

import Language from '@components/Language';

const Footer = () => {
  const scrollToAbout = useCallback(() => {
    scrollTo(`#about-us`, true) || navigate('/#about-us');
  }, []);

  return (
    <Container>
      <Description>
        <FormattedMessage id="footerDescription" />
      </Description>

      <AppLinks>
        <FormattedMessage
          id="footerDownloadApp"
          values={{
            br: <br />,
          }}
        />
        <AppIcons context="footer" stacked />
      </AppLinks>

      <ExternalLinks>
        <li>
          <Link onClick={scrollToAbout}>
            <FormattedMessage id="about" />
          </Link>
        </li>
        <li>
          <Link to="/contact">
            <FormattedMessage id="contact" />
          </Link>
        </li>
        <li>
          <Link to="/catering">Catering</Link>
        </li>
        <li>
          <Link to="/havespace/vitra">Vitra</Link>
        </li>
        <li>
          <Link to="/team">Team</Link>
        </li>
        <li>
          <Link to="https://thisisdenizen.onapply.de">Jobs</Link>
        </li>
      </ExternalLinks>
      <SocialLinks>
        <li>
          <Link to="https://instagram.com/denizen.eiswerk">Instagram</Link>
        </li>
        <li>
          <Link to="https://de.linkedin.com/company/thisisdenizen">LinkedIn</Link>
        </li>
        <li>
          <Link to="https://www.facebook.com/Denizen-Eiswerk-111538668016128/">Facebook</Link>
        </li>
      </SocialLinks>

      <LanguageContainer>
        <Language />
      </LanguageContainer>

      <Copyright>
        © 2022 Denizen. <br />
        All rights reserved.
      </Copyright>

      <LegalLinks>
        <li>
          <Link to="/legal-notice">
            <FormattedMessage id="linkLegalNotice" />
          </Link>
        </li>
        <li>
          <Link to="/terms-of-use">
            <FormattedMessage id="linkTermsOfUse" />
          </Link>
        </li>
        <li>
          <Link to="/privacy-policy">
            <FormattedMessage id="linkPrivacyPolicy" />
          </Link>
        </li>
      </LegalLinks>
    </Container>
  );
};

const Container = styled.footer`
  ${layoutWrapper};
  ${grid};

  --content-color: var(--color-grey);
  font: var(--font-body-mobile);
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
  background-color: var(--color-text);
  color: var(--content-color);
  row-gap: var(--space-xxs);

  ${mq.small} {
    font: var(--font-s);
  }
`;

const Description = styled.div`
  grid-column: 1 / -1;

  ${mq.small} {
    grid-column: 1 / 4;
  }

  ${mq.medium} {
    grid-column: 1 / 5;
  }

  ${mq.large} {
    grid-column: 1 / 4;
  }

  ${mq.huge} {
    grid-column: 2 / 5;
  }
`;

const Link = withLink(styled.a`
  ${linkHover};
`);

const AppLinks = styled.div`
  display: grid;
  row-gap: var(--space-xxs);
  grid-column: 1 / -1;

  ${mq.small} {
    br {
      display: none;
    }
  }

  ${mqdown.small} {
    margin-top: var(--space-s);
  }

  ${mq.small} {
    grid-column: 1 / 4;
    grid-row-end: span 2;
  }

  ${mq.medium} {
    grid-column: 5 / 8;
  }

  ${mq.huge} {
    grid-column: 6 / 9;
  }
`;

const ExternalLinks = styled.ul`
  grid-column: 1 / 3;

  ${mqdown.small} {
    margin-top: var(--space-s);
    margin-bottom: var(--space-m);
  }

  ${mq.small} {
    grid-column: -3 / -1;
    grid-row: 1 / span 2;
  }

  ${mq.medium} {
    grid-column: 9 / span 2;
  }

  ${mq.huge} {
    grid-column: -6 / -4;
  }
`;

const SocialLinks = styled.ul`
  grid-column: 3 / -1;

  ${mqdown.small} {
    margin-top: var(--space-s);
    margin-bottom: var(--space-m);
  }

  ${mq.small} {
    grid-column: -3 / -1;
    grid-row-end: span 2;
  }

  ${mq.medium} {
    grid-column: -3 / -1;
  }

  ${mq.huge} {
    grid-column: -4 / -2;
  }
`;

const LanguageContainer = styled.div`
  grid-column-end: span 1;
  grid-row-start: 4;
  align-self: end;
  ${linkHover};

  ${mqdown.small} {
    font: var(--font-xxs);
    letter-spacing: var(--letter-spacing-xxs);
  }

  ${mq.small} {
    grid-row-start: 6;
    font: var(--font-xs);
  }

  ${mq.medium} {
    grid-row-start: 3;
  }

  ${mq.huge} {
    grid-column-start: 2;
  }
`;

const Copyright = styled.div`
  grid-column-end: span 2;
  grid-row-start: 5;
  align-self: end;

  ${mqdown.small} {
    font: var(--font-xxs);
    letter-spacing: var(--letter-spacing-xxs);
  }

  ${mq.small} {
    grid-row-start: 6;
    grid-column-end: span 3;
    font: var(--font-xs);

    br {
      display: none;
    }
  }

  ${mq.medium} {
    grid-row-start: 3;
  }
`;

const LegalLinks = styled.ul`
  grid-column: 3 / -1;
  grid-row: 4 / span 2;
  white-space: pre;
  align-self: start;

  ${mqdown.small} {
    font: var(--font-xxs);
    letter-spacing: var(--letter-spacing-xxs);
  }

  ${mq.small} {
    grid-column: -3 / -1;
    grid-row: 5 / span 2;
    font: var(--font-xs);
  }

  ${mq.medium} {
    grid-column: -5 / -1;
    grid-row: 3;
    display: grid;
    grid-auto-flow: column;
    column-gap: var(--grid-gap);
    justify-content: start;
    margin-top: var(--space-s);
  }

  ${mq.huge} {
    grid-column: -6 / -1;
  }
`;

export default injectIntl(Footer);
