/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styled from 'styled-components';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-react-intl';

const languageName = {
  en: 'English',
  de: 'Deutsch',
};

const Language = ({ className }) => (
  <div>
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages
          .filter(language => currentLocale !== language)
          .map(language => (
            <LanguageLink
              key={language}
              onClick={() => changeLocale(language)}
              className={className}
            >
              {languageName[language]}
            </LanguageLink>
          ))
      }
    </IntlContextConsumer>
  </div>
);

const LanguageLink = styled.div`
  cursor: pointer;
`;

export default Language;
