const messages = {
  required: 'This field is required',
};

function getInputConfig(name, required) {
  switch (name) {
    case 'firstname':
    case 'lastname':
      return { required: true, maxLength: 100 };
    case 'email':
      return {
        required: true,
        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
      };
    case 'moreAboutEvent':
      return { required: false, maxLength: 1000 };
    case 'company':
    default:
      return { required, maxLength: 80 };
  }
}

function getErrorMessage(name, errors) {
  if (!errors || !errors[name]) {
    return null;
  }

  return messages[errors[name].type];
}

export { getInputConfig, getErrorMessage };
