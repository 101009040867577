/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import './src/styles/fonts/fonts.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

function intersectionObserverSupported() {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    // Minimal polyfill for Edge 15's lack of `isIntersecting`
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
      Object.defineProperty(window.IntersectionObserverEntry.prototype, 'isIntersecting', {
        get() {
          return this.intersectionRatio > 0;
        },
      });
    }
    return true;
  }

  return false;
}

function smoothScrollSupported() {
  return 'scrollBehavior' in document.documentElement.style;
}

function loadScript(src) {
  const js = document.createElement('script');
  js.src = src;
  document.head.appendChild(js);
}

function initTawk() {
  window.Tawk_API = window.Tawk_API || {
    customStyle: {
      visibility: {
        desktop: {
          yOffset: 24,
          xOffset: 20,
        },
        mobile: {
          yOffset: 12,
          xOffset: 8,
        },
      },
    },
    onChatMaximized: () => {
      if (!window || !window.fbq) {
        return;
      }

      window.fbq('trackCustom', `chat`, {
        event: 'maximized',
        location: window.location.href,
      });
    },
  };
  window.Tawk_LoadStart = new Date();

  const s1 = document.createElement('script');
  const s0 = document.getElementsByTagName('script')[0];
  s1.async = true;
  s1.src = 'https://embed.tawk.to/6045e6251c1c2a130d66176e/1f08gm12u';
  s1.charset = 'UTF-8';
  s1.setAttribute('crossorigin', '*');
  s0.parentNode.insertBefore(s1, s0);
}

export const onClientEntry = () => {
  const polyfills = [];

  gsap.registerPlugin(ScrollTrigger);

  if (!intersectionObserverSupported()) {
    polyfills.push('IntersectionObserver');
  }

  if (!smoothScrollSupported()) {
    polyfills.push('smoothscroll');
  }

  if (polyfills.length > 0) {
    loadScript(`https://polyfill.io/v3/polyfill.min.js?features=${polyfills.join('%2C')}`);
  }

  setUrlParameters();
  initTawk();
};

// export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
//   // transition duration from `layout.js` * 1000 to get time in ms
//   // * 2 for exit + enter animation
//   const TRANSITION_DELAY = 0.3 * 1000;
//   // if it's a "normal" route
//   if (location.action === 'PUSH') {
//     window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
//   }
//   // if we used the browser's forwards or back button
//   else {
//     const savedPosition = getSavedScrollPosition(location) || [0, 0];
//     window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY);
//   }
//   return false;
// };

export const shouldUpdateScroll = () => false;

function setUrlParameters() {
  let urlParameters = window.location.search;
  if (!urlParameters) {
    return null;
  }

  if (urlParameters.indexOf('?') >= 0) {
    // eslint-disable-next-line prefer-destructuring
    urlParameters = urlParameters.split('?')[1];
  }

  localStorage.setItem('urlParameters', urlParameters);
  window.location.search = '';
  return null;
}

// export const onRouteUpdate = ({ location }) => {
//   const hash = document.querySelectorAll(`a[href="${location.hash}"]`)[0]
//   if (hash) {
//     window.scrollTo({
//       top: hash.offsetTop,
//     })
//   }
//   return true
// }
