import { css, keyframes } from 'styled-components';

const grid = css`
  display: grid;
  column-gap: var(--grid-gap);
  grid-template-columns: repeat(var(--grid-column-count), 1fr);
`;

const layoutWrapper = css`
  padding-left: var(--gutter);
  padding-right: var(--gutter);
`;

const hyphenate = css`
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: justify;
`;

const truncate = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
`;

const hover = content => css`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${css(content)};
    }
  }
`;

const underline = css`
  background-image: linear-gradient(var(--color-text), var(--color-text));
  background-size: 100% 1px;
  background-position: center calc(100% - 1px);
  background-repeat: no-repeat;
  display: inline-block;
  margin-left: -0.1em;
  margin-right: -0.1em;
  padding-left: 0.1em;
  padding-right: 0.1em;
`;

const linkHover = css`
  opacity: 1;
  transition: opacity var(--motion);

  svg {
    transform: translate3d(0, 0, 0);
  }

  ${hover`
    opacity: 0.4;
  `}
`;

const richText = css`
  p {
    white-space: pre-line;
    z-index: 1;
    position: relative;
  }

  p + p {
    margin-top: 1em;
  }

  p + h3,
  h2 + h3 {
    margin-top: 2em;
  }

  p + h2 {
    margin-top: 1em;
  }

  blockquote + p {
    margin-top: 1em;
  }

  h3 {
    z-index: 1;
    position: relative;
    --color-primary: var(--color-secondary);
  }

  h2,
  h3 {
    text-align: left;
  }

  a {
    ${underline};
    ${linkHover};
  }

  ul {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

    li {
      display: flex;

      &:before {
        content: '•';
        display: block;
        margin: 0 1rem;
        height: 100%;
      }
    }
  }
`;

const opacityKeyframes = keyframes`
  0%{
    opacity: 1;
  }

  33.332% {
    opacity:  1;
  }

  33.333% {
    opacity:  0;
  }

  100% {
    opacity: 0;
  }
`;

const frameAnimation = css`
  .frame {
    animation-name: ${opacityKeyframes};
    animation-duration: 450ms;
    animation-iteration-count: infinite;
    animation-play-state: ${props => (props.inView ? 'initial' : 'paused')};
    opacity: 0;
  }

  .frame-1 {
    opacity: 1;
  }

  .frame-1 {
    animation-delay: 150ms;
  }

  .frame-2 {
    animation-delay: 300ms;
  }

  .frame-3 {
    animation-delay: 450ms;
  }
`;

export {
  richText,
  grid,
  truncate,
  layoutWrapper,
  center,
  hyphenate,
  hover,
  cover,
  frameAnimation,
  underline,
  linkHover,
};
